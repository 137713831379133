import { get, post,deletes, downFile } from 'common/request'

export default {
//根据ID查询
getById: id => get(`/nearby/getById?id=${id}`),
//新增
add: args => post('/nearby/add', args),
//根据IDS删除
ids: args => deletes(`/nearby/ids?ids=${args}`),
//分页查询
list: args => get('/nearby/list', args),
//修改
update: args => post('/nearby/update', args),
//根据ID删除
deleteById: id => deletes(`/nearby/deleteById?id=${id}`),
//获取网格树
getTreeList: args => get('/scGridManagement/getTreeList', args),
// 获取省的行政区划
getRegionInfo: args => get(`/regionInfo/list?level=1`),
// 获取市级的行政区划
getProvinceRegionInfo: provinceCode => get(`/regionInfo/list?level=2&&provinceCode=${provinceCode}`),
// 获取区级的行政区划
getCityRegionInfo: cityCode => get(`/regionInfo/list?level=3&&cityCode=${cityCode}`),
// 获取县级的行政区划
getCountyRegionInfo: countyCode => get(`/regionInfo/list?level=4&&countyCode=${countyCode}`),

//导出
export: args => downFile('/nearby/export', args),
}
