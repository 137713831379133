var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-form',{ref:"formBox",attrs:{"formBuilder":_vm.formBuilder}},[_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"map"},slot:"map"},[_c('ax-button',{attrs:{"type":"#faad14"},on:{"click":function($event){_vm.dialogVisible = true}}},[_vm._v("地图标绘")]),_c('el-dialog',{attrs:{"title":"地图标绘","width":"1000px","visible":_vm.dialogVisible,"z-index":12,"destroy-on-close":true,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('position',{ref:"position",on:{"close":function($event){_vm.dialogVisible = false},"locationUpdated":_vm.handleLocationUpdate}})],1)],1),_c('div',{staticStyle:{"display":"flex"},attrs:{"slot":"gridId"},slot:"gridId"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options',
          { rules: [{ required: true, message: '请选择现住区域' }] },
        ]),expression:"[\n          'options',\n          { rules: [{ required: true, message: '请选择现住区域' }] },\n        ]"}],attrs:{"options":_vm.options,"placeholder":"请选择所属网格","change-on-select":"","allowClear":"","getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666},on:{"change":_vm.onChange}})],1),_c('div',{attrs:{"slot":"address"},slot:"address"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options1',
          { rules: [{ required: true, message: '请选择现住区域' }] },
        ]),expression:"[\n          'options1',\n          { rules: [{ required: true, message: '请选择现住区域' }] },\n        ]"}],attrs:{"getPopupContainer":(triggerNode) => triggerNode.parentNode,"options":_vm.options1,"load-data":_vm.loadData,"placeholder":"请选择位置","allowClear":"","model":_vm.residenceCode},on:{"change":_vm.onChange1}})],1)]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.saveType},on:{"click":_vm.onSubmit}},[_vm._v(" 保存 ")]),_c('a-button',{staticStyle:{"margin-left":"20px"},on:{"click":_vm.closeDialog}},[_vm._v(" 取消 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }